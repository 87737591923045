import React, { ChangeEvent, KeyboardEventHandler, useState } from 'react';
import styles from './Pagination.module.scss';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/store';
import { getPostsWithQuery } from 'redux/reducer';
import { AppDispatch } from 'redux/state.types';

export const Pagination = () => {
  const { page, totalPages } = useAppSelector((state) => state);
  const dispatch = useDispatch<AppDispatch>();

  const [state, setState] = useState(+page);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      onPageChange(+state);
    }
  };

  const handleNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === page) return;
    let p = +e.target.value;
    if (p < 1) p = 1;
    if (p > +totalPages) p = +totalPages;
    setState(p);
  };

  const onPageChange = (n: number) => {
    dispatch(getPostsWithQuery(n.toString()));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.pagination}>
        <button
          className={styles.button}
          disabled={+page <= 1}
          onClick={() => onPageChange(+page - 1)}
        >
          Prev
        </button>
        <input
          type="number"
          min={1}
          max={totalPages}
          placeholder={page}
          value={state}
          onChange={handleNumberChange}
          disabled={+totalPages === 1}
          onKeyDown={handleKeyDown}
          className={styles.input}
        />
        <button
          className={styles.button}
          disabled={+page >= +totalPages}
          onClick={() => onPageChange(+page + 1)}
        >
          Next
        </button>
      </div>
      <div className={styles.pages}>
        Page <b>{page}</b> of <b>{totalPages}</b>
      </div>
    </div>
  );
};
