import React from 'react';
import { User } from 'components/Form/Form.types';
import styles from './UserCard.module.scss';
import lock from '../../assets/svg/lock.svg';
import unlock from '../../assets/svg/unlock.svg';

export const UserCard = ({ card }: { card: User }) => {
  const background = `center / contain url(${card.public ? unlock : lock}) no-repeat`;

  return (
    <div className={styles.wrapper}>
      <div className={styles.avatar}>
        <img src={card.avatar} alt="avatar" />
        <div className={styles.lock} style={{ background: background }} />
      </div>
      <div className={styles.about}>
        <p>
          Name: <b>{card.name}</b>
        </p>
        <p>
          Birthday: <b>{card.birthday}</b>
        </p>
        <p>
          Role: <b>{card.role}</b>
        </p>
        <p>
          Preferences: <b>{card.preferences}</b>
        </p>
      </div>
    </div>
  );
};
