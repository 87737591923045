import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styles from './Header.module.scss';
import { defineHeaderStyle } from './Header.utils';

export const Header = () => {
  const { pathname } = useLocation();
  const header = pathname.match(/posts/) ? pathname.slice(7) : null;

  return (
    <header className={styles.header} style={defineHeaderStyle(pathname)}>
      <nav>
        <ul className={styles.list}>
          <li>
            <NavLink className={({ isActive }) => (isActive ? styles.active : '')} to="/" end>
              Main
            </NavLink>
          </li>
          <li>
            <NavLink className={({ isActive }) => (isActive ? styles.active : '')} to="/about">
              About
            </NavLink>
          </li>
          <li>
            <NavLink className={({ isActive }) => (isActive ? styles.active : '')} to="/forms">
              Forms
            </NavLink>
          </li>
        </ul>
      </nav>
      {header && <h2 className={styles.location}>{header}</h2>}
    </header>
  );
};
