import { configureStore } from '@reduxjs/toolkit';
import { CountTypes, Post, SortTypes } from 'components/ApiProvider/Api.types';
import { User } from 'components/Form/Form.types';
import { getLocalStorage } from 'components/SearchBar/utils';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { appReducer } from './reducer';
import { AppStateType } from './state.types';

export const useAppSelector: TypedUseSelectorHook<AppStateType> = useSelector;

const preloadedState = {
  searchParams: {
    query: getLocalStorage(),
    sort: SortTypes.POPULAR,
    count: CountTypes.MEDIUM,
  },
  page: '1',
  totalPages: '1',
  isLoading: false,
  posts: [] as Post[],
  users: [] as User[],
};

export const store = configureStore<AppStateType>({
  reducer: appReducer,
  preloadedState,
});
