const nameValidator = /^[a-zA-Z\-\ ]+$/;
const extensionValidator = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

export const validateAge = (birthday: string) => {
  if (!birthday) {
    return { birthday: 'Enter you birthday' };
  }

  const date = new Date();
  date.setFullYear(date.getFullYear() - 12);

  if (Date.parse(birthday) >= +date) {
    return { birthday: 'Only for 12+' };
  }
};

export const validateName = (name: string) => {
  if (name.length < 2) {
    return { name: 'Name must contain at least 2 characters' };
  }
  if (!nameValidator.test(name)) {
    return { name: 'Name must contain only characters' };
  }
};

export const validatePreferences = (preferences: string) => {
  if (preferences === 'select') {
    return { preferences: 'Choose your preferences' };
  }
};

export const validateConditions = (conditions: boolean) => {
  if (!conditions) {
    return { conditions: 'You must accept the terms of use!' };
  }
};

export const validateFile = (extension: string) => {
  if (!extension) return;
  if (!extension.match(extensionValidator)) {
    return { image: 'file must be an image!' };
  }
};
