import React from 'react';
import { Form } from 'components/Form';
import { User } from '../../components/Form/Form.types';
import { UserCard } from 'components/UserCard';
import { Modal } from 'components/Modal';
import { useModal } from '../../components/Modal/Modal.hooks';
import { setUsers } from '../../redux/reducer';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/store';

export const Forms = () => {
  const users = useAppSelector((state) => state.users);
  const dispatch = useDispatch();
  const { modal, setModal } = useModal();

  const addUser = (user: User) => {
    setModal(true);
    dispatch(setUsers(user));
  };

  return (
    <>
      {modal && (
        <Modal close={() => setModal(false)}>
          <p className="info">Data saved successfully!</p>
        </Modal>
      )}
      <h1>Forms</h1>
      <Form addUser={addUser} />
      {users && users.map((user) => <UserCard key={user.id} card={user} />)}
    </>
  );
};
