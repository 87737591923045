import React, { ChangeEvent, FormEvent, useEffect } from 'react';
import { SortTypes, CountTypes } from 'components/ApiProvider/Api.types';
import { setLocalStorage } from './utils';
import styles from './SearchBar.module.scss';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/store';
import { getPostsWithQuery, setSearchParams } from 'redux/reducer';
import { AppDispatch } from 'redux/state.types';

export const SearchBar = () => {
  const searchParams = useAppSelector((state) => state.searchParams);
  const dispatch = useDispatch<AppDispatch>();

  const { count, query, sort } = searchParams;

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(getPostsWithQuery('1'));
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchParams({ ...searchParams, query: e.target.value }));
  };

  const handleSortChange = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch(setSearchParams({ ...searchParams, sort: e.target.value as SortTypes }));
  };

  const handleCountChange = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch(setSearchParams({ ...searchParams, count: e.target.value as CountTypes }));
  };

  useEffect(() => setLocalStorage(query), [query]);

  return (
    <form className={styles.wrapper} onSubmit={handleSubmit}>
      <input
        type="search"
        className={styles.searchBar}
        value={query}
        onChange={handleSearchChange}
      />
      <select className={styles.select} defaultValue={count} onChange={handleCountChange}>
        <option value={CountTypes.LESS}>6</option>
        <option value={CountTypes.MEDIUM}>12</option>
        <option value={CountTypes.MORE}>25</option>
      </select>
      <select className={styles.select} defaultValue={sort} onChange={handleSortChange}>
        <option value={SortTypes.LATEST}>latest</option>
        <option value={SortTypes.OLDEST}>oldest</option>
        <option value={SortTypes.POPULAR}>popular</option>
        <option value={SortTypes.VIEWS}>views</option>
        <option value={SortTypes.DOWNLOADS}>downloads</option>
      </select>
      <button type="submit" className={styles.searchButton}>
        Search...
      </button>
    </form>
  );
};
