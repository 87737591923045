import React, { createRef, FormEvent, RefObject, useState } from 'react';
import styles from './Form.module.scss';
import { FormProps, Errors } from './Form.types';
import {
  validateAge,
  validateConditions,
  validateFile,
  validateName,
  validatePreferences,
} from './Form.utils';

export const Form = ({ addUser }: FormProps) => {
  const [errors, setErrors] = useState<Errors>({});
  const [disabled, setDisabled] = useState(true);

  const form: RefObject<HTMLFormElement> = createRef();
  const name: RefObject<HTMLInputElement> = createRef();
  const birthday: RefObject<HTMLInputElement> = createRef();
  const role: RefObject<HTMLInputElement> = createRef();
  const preferences: RefObject<HTMLSelectElement> = createRef();
  const conditions: RefObject<HTMLInputElement> = createRef();
  const isPublic: RefObject<HTMLInputElement> = createRef();
  const image: RefObject<HTMLInputElement> = createRef();

  const resetError = (error: keyof Errors) => {
    if (errors[error]) {
      delete errors[error];
      setErrors({ ...errors });
    }
    if (hasNoErrors()) enableSubmit();
  };

  const enableSubmit = () => {
    if (disabled) {
      setDisabled(false);
    }
  };

  const hasNoErrors = () => !Object.keys(errors).length;

  const validateForm = () => {
    const validationErrors = {
      ...validateName(name.current!.value),
      ...validateAge(birthday.current!.value),
      ...validatePreferences(preferences.current!.value),
      ...validateConditions(conditions.current!.checked),
      ...validateFile(image.current!.value),
    };

    if (Object.keys(validationErrors).length) {
      return validationErrors;
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (validationErrors) {
      setErrors(validationErrors);
      setDisabled(true);
    } else {
      addUser(createUser());
      handleClear();
      form.current!.reset();
    }
  };

  const createUser = () => {
    const id = Date.now().toString();
    const url = image.current?.value
      ? URL.createObjectURL(image.current.files![0])
      : './avatar-placeholder.png';

    return {
      id,
      name: name.current!.value,
      birthday: birthday.current!.value,
      role: role.current!.checked ? 'photographer' : 'user',
      preferences: preferences.current!.value,
      conditions: conditions.current!.checked,
      public: isPublic.current!.checked,
      avatar: url,
    };
  };

  const handleClear = () => {
    setErrors({});
    setDisabled(true);
  };

  return (
    <>
      <form className={styles.form} ref={form} onSubmit={handleSubmit}>
        <label className={styles.label}>
          <span>Name: </span>
          <input type="text" ref={name} onChange={() => resetError('name')} />
          {errors.name && <span className={styles.error}>{errors.name}</span>}
        </label>

        <label className={styles.label}>
          <span>Birthday: </span>
          <input type="date" ref={birthday} onChange={() => resetError('birthday')} />
          {errors.birthday && <span className={styles.error}>{errors.birthday}</span>}
        </label>

        <label className={styles.label}>
          <div>Role: </div>
          <span>user </span>
          <input type="checkbox" ref={role} className={styles.switch} />
          <span> photographer</span>
        </label>

        <label className={styles.label}>
          <span>Preferences: </span>
          <select
            defaultValue="select"
            ref={preferences}
            onChange={() => resetError('preferences')}
          >
            <option value="select" disabled hidden>
              select
            </option>
            <option value="nature">Nature</option>
            <option value="architecture">Architecture</option>
            <option value="science">Science</option>
            <option value="people">People</option>
            <option value="abstract">Abstract</option>
          </select>
          {errors.preferences && <span className={styles.error}>{errors.preferences}</span>}
        </label>

        <label className={styles.label}>
          <input
            type="checkbox"
            name="addons"
            value="conditions"
            ref={conditions}
            onClick={() => resetError('conditions')}
          />
          <span> I accept the terms of use</span>
          {errors.conditions && <span className={styles.error}>{errors.conditions}</span>}
        </label>

        <label className={styles.label}>
          <input type="checkbox" name="addons" value="public" ref={isPublic} />
          <span> Make profile public</span>
        </label>

        <label className={styles.label}>
          <span>Upload avatar </span>
          <input type="file" ref={image} onClick={() => resetError('image')} />
          {errors.image && <span className={styles.error}>{errors.image}</span>}
        </label>

        <input className={styles.button} type="submit" disabled={disabled} data-testid="submit" />
        <input className={styles.button} type="reset" onClick={handleClear} />
      </form>
    </>
  );
};
