import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { FullCard } from 'components/FullCard';
import styles from './Post.module.scss';
import { useAppSelector } from 'redux/store';

export const Post = () => {
  const { pathname } = useLocation();
  const posts = useAppSelector((state) => state.posts);
  const id = pathname.slice(7);
  const post = posts.find((el) => el.id === id);

  if (!post) return <Navigate to="/" />;

  return (
    <div className={styles.wrapper}>
      {post && <FullCard post={post} />}
      <button className={styles.button} onClick={() => history.back()}>
        Go back
      </button>
    </div>
  );
};
