import React from 'react';
import { Post } from '../ApiProvider/Api.types';
import styles from './Card.module.scss';
import { NavLink } from 'react-router-dom';

type CardProps = {
  post: Post;
};

export const Card = ({ post }: CardProps) => {
  let description = post.description || post.alt_description;
  if (description && description.length > 100) description = `${description.slice(0, 100)}...`;

  return (
    <NavLink to={`/posts/${post.id}`} className={styles.wrapper}>
      <img className={styles.picture} src={post.urls.thumb} alt="" />
      <div className={styles.description}>
        <p>
          <span className="likes" /> <b>{post.likes} likes</b>
        </p>
        <p>{description}</p>
        <p className={styles.author}>
          by&nbsp;
          <span>{post.user.username}</span>
        </p>
      </div>
    </NavLink>
  );
};
