import { createAction, createAsyncThunk, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { getPosts } from 'components/ApiProvider';
import { CountTypes, SortTypes, Response, Post } from 'components/ApiProvider/Api.types';
import { User } from 'components/Form/Form.types';
import { getLocalStorage } from 'components/SearchBar/utils';
import { AsyncThunkConfig, SearchParamsType } from './state.types';

const initialState = {
  searchParams: {
    query: getLocalStorage(),
    sort: SortTypes.POPULAR,
    count: CountTypes.MEDIUM,
  },
  page: '1',
  totalPages: '1',
  isLoading: false,
  posts: [] as Post[],
  users: [] as User[],
};

export const getPostsWithQuery = createAsyncThunk<Response, string, AsyncThunkConfig>(
  'getPosts',
  async (page, { getState }) => {
    const searchParams = getState().searchParams;
    const response = await getPosts({ ...searchParams, page });
    return { ...response, page };
  }
);

export const setSearchParams = createAction<SearchParamsType>('setSearchParams');
export const setUsers = createAction<User>('setUsers');

export const appReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setSearchParams, (state, action: PayloadAction<SearchParamsType>) => {
      state.searchParams = action.payload;
    })
    .addCase(setUsers, (state, action: PayloadAction<User>) => {
      state.users.push(action.payload);
    })
    .addCase(getPostsWithQuery.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getPostsWithQuery.fulfilled, (state, action) => {
      state.isLoading = false;
      const { results, total_pages, page } = action.payload;
      state.page = page || '1';
      state.posts = results;
      state.totalPages = `${total_pages}`;
    })
    .addCase(getPostsWithQuery.rejected, (state) => {
      state.isLoading = false;
      [state.posts, state.totalPages] = [[], ''];
    });
});
