import { Response, SearchParamsType } from './Api.types';

const api = 'https://api.unsplash.com/search/photos?query=';
const myId = '&client_id=6ZdbFfCvoSKtMFAZYhY86hQZkI_5BvBPSfD87Em7A5M';

export const getPosts = async (props: SearchParamsType): Promise<Response> => {
  try {
    const perPage = `&per_page=${props.count}`;
    const orderBy = `&order_by=${props.sort}`;
    const page = `&page=${props.page}`;
    const url = api + props.query + perPage + orderBy + page + myId;
    const response = await fetch(url);

    if (response.status !== 200) {
      throw new Error();
    }

    const { results, total_pages } = (await response.json()) as Response;

    return { results, total_pages };
  } catch {
    return { results: [], total_pages: 0 };
  }
};
