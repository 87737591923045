import React from 'react';
import { Posts } from 'components/Posts';
import { SearchBar } from 'components/SearchBar';
import { Pagination } from 'components/Pagination';
import { useAppSelector } from 'redux/store';

export const Main = () => {
  const { posts, isLoading } = useAppSelector((state) => state);

  let content = null as JSX.Element | null;
  if (isLoading) {
    content = <div className="info">Loading...</div>;
  } else {
    if (posts.length) {
      content = <Posts posts={posts} />;
    } else {
      content = <div className="info">Sorry, no results</div>;
    }
  }

  return (
    <>
      <SearchBar />
      {content}
      <Pagination />
    </>
  );
};
