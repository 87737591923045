import React, { ReactNode } from 'react';
import styles from './Modal.module.scss';

type ModalProps = {
  children: ReactNode;
  close: () => void;
};

export const Modal = ({ children, close }: ModalProps) => {
  return (
    <div className={styles.modal} onClick={close} data-testid="modal window">
      <div className={styles.content} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};
