import React from 'react';
import styles from './NotFound.module.scss';

export const NotFound = () => (
  <div className={styles.wrapper}>
    <div className={styles.picture}></div>
    <div className={styles.description}>
      <h1>404</h1>
      <h2>Oops! We&apos;re not here</h2>
      <button className={styles.button} onClick={() => history.back()}>
        Go back
      </button>
    </div>
  </div>
);
