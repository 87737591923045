import data from './Posts.json';

export type Post = typeof data[0] | typeof data[1];

export type SearchParamsType = {
  query: string;
  sort: SortTypes;
  count: CountTypes;
  page: string;
};

export enum SortTypes {
  OLDEST = 'oldest',
  LATEST = 'latest',
  POPULAR = 'popular',
  VIEWS = 'views',
  DOWNLOADS = 'downloads',
}

export enum CountTypes {
  LESS = '6',
  MEDIUM = '12',
  MORE = '25',
}

export interface Response {
  total_pages: number;
  results: Array<Post>;
  page?: string;
}
