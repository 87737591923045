import React from 'react';
import { Post } from 'components/ApiProvider/Api.types';
import styles from './FullCard.module.scss';

export const FullCard = ({ post }: { post: Post }) => {
  // eslint-disable-next-line
  // @ts-ignore
  const tags: string = post.tags.reduce((acc, tag) => `${acc} #${tag.title}`, '');
  const date = new Date(post.created_at).toLocaleDateString();

  return (
    <div className={styles.wrapper}>
      <div className={styles.photo}>
        <img src={post.urls.regular} alt="" />
      </div>
      <div className={styles.description}>
        <div className={styles.author}>
          <div>
            <a href={post.user.links.html} target="_blank" rel="noreferrer">
              {post.user.first_name} {post.user.last_name}
            </a>
            <p className={styles.small}>{post.user.location}</p>
          </div>
          <img src={post.user.profile_image.small} alt="avatar" />
        </div>
        {post.description && <p>{post.description}</p>}
        <p>{post.alt_description}</p>
        <p>{tags}</p>
        <p className={styles.small}>{date}</p>
        <p className={styles.likes}>
          <span className="likes" />
          <b> {post.likes} likes</b>
        </p>
      </div>
    </div>
  );
};
