import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Header } from 'components/Header';
import { About } from 'pages/about';
import { Forms } from 'pages/forms';
import { Main } from 'pages/main';
import { NotFound } from 'pages/not-found';
import { Post } from 'pages/post';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/state.types';
import { getPostsWithQuery } from 'redux/reducer';

export const Router = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getPostsWithQuery('1'));
  }, []);

  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route index element={<Main />} />
        <Route path="about" element={<About />} />
        <Route path="forms" element={<Forms />} />
        <Route path="posts/:id" element={<Post />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
