import React from 'react';
import { Provider } from 'react-redux';
import { store } from 'redux/store';
import { Router } from '../Router';

export const App = () => (
  <div className="container">
    <Provider store={store}>
      <Router />
    </Provider>
  </div>
);
