import React from 'react';
import { Post } from '../ApiProvider/Api.types';
import { Card } from 'components/Card';
import styles from './Posts.module.scss';

type PostProps = {
  posts: Post[];
};

export const Posts = ({ posts }: PostProps) => (
  <div className={styles.wrapper}>
    {posts.map((post) => (
      <Card key={post.id} post={post} />
    ))}
  </div>
);
